<template>

    <!-- <van-nav-bar :title="title" /> -->

    <div class="navbar">
        <div class="return" v-if="back" @click="back">
            <img src="@/assets/return.png" />
        </div>
        <span>{{ title }}</span>
    </div>

</template>

<script setup>

import { useRouter, useRoute } from 'vue-router/composables';

const router = useRouter();

const props = defineProps({
    title: String,
    back: Boolean,
    backurl: String,
})

function back() {
    if (props.backurl) {
        location.href = props.backurl
        return
    }
    router.back()
}

</script>

<style lang="stylus" scoped>

.navbar {
    height: 88px;
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
    background-color: #fff;
}

.return {
    position: absolute;
    left: 30px;
    top: 0;
    height: 88px;
    display: flex;
    align-items: center;
    width: 88px;
    img {
        height: 32px;
    }
    
}

</style>