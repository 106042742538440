<template>

    <div class="page">
        <Navbar title="订单中心" :back="true" />

        <div class="tab">
            <div v-for="(item, index) in tabs" :key="index" class="tab-item"
                :class="{ 'tab-item-on': activeIndex === index }" @click="switchTab(index)">
                {{ item.name }}
            </div>
        </div>

        <div class="tab-content-wrap">
            <div class="tab-content" :style="{ height: visibleHeight + 'px' }" @scroll="handleScroll">
                <div class="tab-content-item" v-for="(item, index) in orderList">
                    <router-link :to="`/order/detail?id=` + item.id" class="order-info">
                        <div class="order-content">
                            <div class="order-num">订单号：{{ item.order_no }}</div>
                            <div class="order-name">套餐：{{ item.product_name }}</div>
                            <div class="order-time">下单时间：{{ item.created_at }}</div>
                        </div>
                        <div class="order-status">{{ $function.orderStatus(item.order_status) }}</div>
                    </router-link>
                    <div class="order-pay">
                        <div class="pay-money">¥{{ item.total_amount }}</div>
                        <div class="pay-btns" v-if="activeIndex == '00'">
                            <div class="pay-btn pay-cancel" @click="cancelOrder(item.order_no)">取消</div>
                            <div class="pay-btn pay-now" @click="buyNow(item.order_no)">支付</div>
                        </div>
                        <div class="pay-btns" v-else-if="activeIndex == '01'">
                            <router-link class="pay-btn pay-now" :to="`/home/detail?id=` + item.package_id">再次购买</router-link>
                        </div>
                    </div>
                </div>
                <!-- 加载提示 -->
                <div v-if="loading" class="loader">Loading...</div>
                <!-- 没有更多数据提示 -->
                <div v-if="!loading && !hasMore" class="no-more-items">没有更多数据了</div>
            </div>
        </div>

    </div>

</template>

<script setup>
import { ref } from 'vue'
import Navbar from '../../components/navbar.vue';
import * as api from '../../http-service/api-client';
import * as $function from '../../share/function.js';


const tabs = ref({
    '00': { name: '待支付' },
    '01': { name: '完成' },
    '03': { name: '取消' },
})

const activeIndex = ref('00')
const orderList = ref([])
const loading = ref(false); // 是否正在加载数据
const hasMore = ref(true); // 是否还有更多数据
const this_page = ref(1); // 页码
const visibleHeight = ref(0); // 高度

visibleHeight.value = window.innerHeight - 86; // 更新可视高度

const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const reachedBottom = scrollTop + clientHeight >= scrollHeight - 1;

    if (reachedBottom && !loading.value && hasMore.value) {
        orders();
    }
};


// 初始加载数据
orders();


function switchTab(index) {
    activeIndex.value = index
    hasMore.value = true
    loading.value = false
    this_page.value = 1
    orderList.value = []
    orders();
}

async function orders() {

    try {
        if (loading.value || !hasMore.value) return;

        loading.value = true;

        const result = (await api.orders({ page: this_page.value, order_status: activeIndex.value })).data;

        if (result.code == 200) {
            // orderList.value = result.data.list

            orderList.value = [...orderList.value, ...result.data.list];
            console.log('orderList.value``````````')
            console.log(orderList.value)

            // hasMore.value = result.data.last_page <= this_page.value ? false : true;
            if (result.data.last_page <= this_page.value) {
                hasMore.value = false
            } else {
                hasMore.value = true
            }

        }
        // loading.value = false;
    } catch (e) {

        console.log(e.message)
    }

    loading.value = false;

}

// 表单防重，是否可以提交表单
var isSubmit = true 

// 支付
async function buyNow(order_no) {
    if (isSubmit === false) {
        console.log('不可重复提交')
        return false
    }
    isSubmit = false
    
    var args = {
        orderNo: order_no
    }
    var result = (await api.buyNow(args)).data
    if (result.code != 200) {
        alert(result.msg)
    }    

    var data = {
        keyCiphertext: result.data.keyCiphertext,
        textToDecrypt: result.data.textToDecrypt,
        signature: result.data.signature,
        appId: result.data.appId,
        businessType: "06",
        // isCallback: false
    }
    console.log('cgbPay-request-order````````````````')
    console.log(data)
    AlipayJSBridge.call('cgbPay', data,
    (res) => {
        console.log('cgbPay-response-order````````````````')
        console.log(res)
        isSubmit = true
        if (res.respCode == '0000') {
            alert(res.respMsg)
            console.log('id````````````')
            console.log(result.data.id)

            location.reload();
        } else {
            
            var msg = res.errorMsg ? res.errorMsg : res.respMsg

            alert(msg)
        }
    }
)
}

// 取消支付
async function cancelOrder(order_no) {
    var args = {
        orderNo: order_no
    }
    var result = (await api.cancelOrder(args)).data
    if (result.code != 200) {
        alert(result.msg)
    } else {
        alert('操作成功')
        location.reload();
    }

}

</script>


<style lang="stylus" scoped>
.page {
    color: #333;
    padding-top 88px;
    background rgba(243,243, 243,1)
    min-height: 100vh
}

.tab {
    height: 85px;
    background-color: #efeff1;
    display flex
    gap 12px
    padding-top 11px

    position: fixed;
    left: 0;
    top: 80px;
    width: 100%;
}

.tab-item {
    flex: 1;
    border-radius: 24px 24px 0 0;
    background-color: #EBEBEB;
    font-size: 26px;
    color: #858585;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-item-on {
    background-color: #fff;
    color: #333;
}
.tab-content{
    overflow-y: auto;
    margin-top:85px;
}

.tab-content-item {
    background-color #fff
    border-radius 12px
    margin 30px;
    display block;
    padding 30px
 

    .order-info {
         
        padding-bottom: 22px;
        border-bottom: 1px solid #D8D8D8;
        font-size: 26px;
        display: flex;
        justify-content: space-between;
        font-weight 500;
        div {
            line-height 37px;       
        }
        .order-num {            
            text-decoration underline
            margin-bottom 11px
        }
        .order-name {
            margin-bottom 11px
        }
      
    }

    

    .order-status {
        font-size: 26px;
    }

    .order-pay {
        display: flex;
        justify-content: space-between;
        margin-top 23px

        .pay-money {
            color: #F65252;
            font-weight 500
            font-size 33px
        }

        .pay-btns {
            display: flex;
            // gap: 14px;
            & > * {
                margin-left: 14px
            }
        }

        .pay-btn {
            border-radius: 8px;
            border: 1px solid #333;
            display: flex;
            line-height 37px;
            font-size 26px;
            font-weight 500;
            align-items center;
            justify-content center;
            width 134px
        
        }

        .pay-now {
            border-color: #FF7979;
            background-color: #FF7979;
            color: #fff;

        }
    }
}
.loader,.no-more-items{
    color:#9e9c9c;
    text-align:center;
    padding:10px 0 20px 0;
    font-size:32px;
}
</style>