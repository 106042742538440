import { get, post } from './http-client.js';
import { objectTranferArray } from '../share/methods.js';


export async function orders(args = {}) {
    return await post('/api/orders', args);
}

export async function order(id,args = {}) {
    return await post('/api/order/'+id, args);
}

export async function couponsPackage(args = {}) {
    return await post('/api/couponsPackage', args);
}

export async function couponsPackageDetail(id,args = {}) {
    return await post('/api/couponsPackage/detail/'+id, args);
}

export async function getUserInfo(args = {}) {
    return await post('/api/getUserInfo', args);
}

export async function createOrder(args = {}) {
    return await post('/api/createOrder', args);
}

export async function buyNow(args = {}) {
    return await post('/api/buyNow', args);
}

export async function cancelOrder(args = {}) {
    return await post('/api/cancelOrder', args);
}

export async function ordersDetail(id) {
    return await post('/api/order/' + id);
}