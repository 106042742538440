<template>

    <div class="page">

        <div>
            <img src="@/assets/package/header.png" alt="">
        </div>

        <div class="row row-1">
            <div class="flex1">
                <div class="row-h">
                    <img class="icon" src="@/assets/package/car.png" alt="">
                    <span class="row-h-txt">
                        <span class="price">3元</span>
                        <span>停车立减券包</span>
                    </span>
                </div>
                <div class="row-c row-c-1">
                    <div class="row-c-t">
                        <b>1</b>
                        <div>
                            <div class="number">x3</div>
                            <span>元</span>
                        </div>
                    </div>
                    <div class="ticket">立减券</div>
                </div>
                <router-link class="row-link" to="/package/info?id=11" />
            </div>
            <div class="flex1">
                <div class="row-h">
                    <img class="icon" src="@/assets/package/car.png" alt="">
                    <span class="row-h-txt">
                        <span class="price">15元</span>
                        <span>停车立减券包</span>
                    </span>
                </div>
                <div class="row-c row-c-2">
                    <div class="row-c-t">
                        <b>5</b>
                        <div>
                            <div class="number">x3</div>
                            <span>元</span>
                        </div>
                    </div>
                    <div class="ticket">立减券</div>

                </div>

                <router-link class="row-link" to="/package/info?id=12" />

            </div>
        </div>

        <router-link to="/package/info?id=13" class="row row-2">
            <div class="  row-h-big">
                <img class="icon" src="@/assets/package/car.png" alt="">
                <span class="row-h-txt">
                    <span class="price">8元</span>
                    <span>停车立减券包</span>
                </span>
            </div>
            <div class="row-center">
                <div class="row-c ">
                    <div class="row-c-t">
                        <b>3</b>
                        <div>
                            <div class="number">x2</div>
                            <span>元</span>
                        </div>
                    </div>
                    <div class="ticket">立减券</div>

                </div>
                <div class="row-c ">
                    <div class="row-c-t">
                        <b>2</b>
                        <span>元</span>
                    </div>
                    <div class="ticket">立减券</div>
                </div>
            </div>

        </router-link>

        <router-link to="/package/detail?id=9" class="row-wrap">
            <div class="  row-h-big">
                <img class="icon" src="@/assets/package/car.png" alt="">
                <span class="row-h-txt">
                    <span class="price">80元</span>
                    <span>车位优选组合</span>
                </span>
            </div>
            <div class="row row-2 row-3">

                <div class="row-center">
                    <div class="row-c">
                        <div class="row-c-t">
                            <b>5</b>
                            <div>
                                <div class="number">x2</div>
                                <span>元</span>
                            </div>
                        </div>
                        <div class="ticket">立减券</div>

                    </div>
                    <div class="row-c">
                        <div class="row-c-t">
                            <b>3</b>
                            <div>
                                <span>元</span>
                            </div>
                        </div>
                        <div class="ticket">立减券</div>
                    </div>
                    <div class="row-c">
                        <div class="row-c-t">
                            <b>2</b>
                            <div>
                                <span>元</span>
                            </div>
                        </div>
                        <div class="ticket">立减券</div>
                    </div>
                </div>

            </div>
            <div class="row row-2 row-4">
                <div class="row-center">
                    <div class="row-c ">
                        <div class="row-c-t">
                            <b>15</b>
                            <div>
                                <div class="number">x3</div>
                                <span>元</span>
                            </div>
                        </div>
                        <div class="ticket">立减券</div>

                    </div>
                    <div class="row-c ">
                        <div class="row-c-t">
                            <b>10</b>
                            <div>
                                <div class="number">x2</div>
                                <span>元</span>
                            </div>
                        </div>
                        <div class="ticket">立减券</div>
                    </div>

                </div>

            </div>
            <div class="btn">
                <img src="@/assets/package/btn.png" alt="">
                <div class="tip">限时25元</div>
            </div>
        </router-link>

        <router-link to="/package/detail?id=10" class="row-wrap">
            <div class="  row-h-big">
                <img class="icon icon2" src="@/assets/package/car2.png" alt="">
                <span class="row-h-txt">
                    <span class="price">80元</span>
                    <span>充电组合</span>
                </span>
            </div>
            <div class="row row-2 row-3">

                <div class="row-center">

                    <div class="row-c ">
                        <div class="row-c-t">
                            <b>5</b>
                            <div>
                                <div class="number">x3</div>
                                <span>元</span>
                            </div>
                        </div>
                        <div class="ticket">立减券</div>

                    </div>
                    <div class="row-c ">
                        <div class="row-c-t">
                            <b>3</b>
                            <div>
                                <span>元</span>
                            </div>
                        </div>
                        <div class="ticket">立减券</div>
                    </div>
                    <div class="row-c ">
                        <div class="row-c-t">
                            <b>2</b>
                            <div>
                                <span>元</span>
                            </div>
                        </div>
                        <div class="ticket">立减券</div>
                    </div>
                </div>

            </div>
            <div class="row row-2 row-5">

                <div class="row-center">

                    <div class="row-c ">
                        <div class="row-c-t">
                            <b>15</b>
                            <div>
                                <div class="number">x3</div>
                                <span>元</span>
                            </div>
                        </div>
                        <div class="ticket">立减券</div>

                    </div>
                    <div class="row-c ">
                        <div class="row-c-t">
                            <b>10</b>
                            <div>
                                <div class="number">x2</div>
                                <span>元</span>
                            </div>
                        </div>
                        <div class="ticket">立减券</div>
                    </div>

                </div>

            </div>
            <div class="btn">
                <img src="@/assets/package/btn.png" alt="">
                <div class="tip">限时25元</div>
            </div>
        </router-link>
        <div class="adtip">广告</div>

    </div>
</template>

<script>
import * as api from '../../http-service/api-client';
// import { useRoute } from 'vue-router/composables';

export default {
    name: 'Package',
    data(){
        return {
            cpupons: {},
        }
    },
    methods: {
        // 获取首页数据
        couponsPackage: function() {
            try {
                api.couponsPackage().then(result => {
                    if (result.data.code == 200) {
                        this.coupon = result.data.data
                        // console.log(this.coupon)
                    }
                })
            } catch (e) {
                console.log(e.message)
            }
        },
        // 获取授权
        cgbGetAuthCode: function () {
            AlipayJSBridge.call('cgbGetAuthCode', {
                scope: 'auth_user',
                appId: '01202404190000020001',
                receiverAppId: '01202404190000020001',
                receiverMerId: '0120240419000002'
            },
            (res) => {
                api.getUserInfo({ "authCode": res.authCode }).then(result => {
                    if (result.data.msg == '') {
                        const userInfo = JSON.stringify(result.data.data)
                        sessionStorage.setItem('userInfo', userInfo)
                    }
                })
            })    
        }
    },
    created(){
        this.couponsPackage()
        this.cgbGetAuthCode()
    }
}

</script>

<style lang="stylus" scoped src="./style.styl">
</style>