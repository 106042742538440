<template>

    <div class="page">
        <Navbar title="订单详情" :back="true" />

        <div class="status-icon">
            <img v-if="orderItem.order_status == '01'" src="@/assets/icon-success.png" alt="">
            <img v-if="orderItem.order_status == '00'" src="@/assets/icon-cancel.png" alt="">
        </div>
        <div class="status-text">
            {{ $function.orderStatus(orderItem.order_status) }}
        </div>

        <div v-show="orderItem.order_status == '00'" class="status-countdown">
            <!-- 这个div不能删，对span做显示隐藏 -->
            <span>剩余：{{ $function.getMinutesDiff(orderItem.lose_time) }}分钟</span>
        </div>

        <div class="status-order">
            <div>订单时间：{{ orderItem.created_at }}</div>
            <div>订单号：{{ orderItem.order_no }}</div>
        </div>

        <div class="order-info">
            <div class="order-name">{{ orderItem.product_name }}</div>
            <div class="order-item">
                <div class="order-label">订单金额：</div>
                <div class="order-value">{{ orderItem.cash_amount }}元</div>
            </div>
            <div class="order-item">
                <div class="order-label">订单总额：</div>
                <div class="order-value">{{ orderItem.total_amount }}元</div>
            </div>
        </div>

        <!-- 优惠金额 -->
        <div v-show="orderItem.total_amount != orderItem.cash_amount" class="order-info">
            <!-- <div class="order-name">优惠详情</div> -->
            <div class="order-item" v-show="orderItem.integral_amount > 0">
                <div class="order-label">积分抵扣金额：</div>
                <div class="order-value">{{ orderItem.integral_amount }}元</div>
            </div>
            <div class="order-item" v-show="orderItem.random_act_amt > 0">
                <div class="order-label">随机减：</div>
                <div class="order-value">{{ orderItem.random_act_amt }}元</div>
            </div>
            <div class="order-item" v-show="orderItem.fin_coup_amt > 0">
                <div class="order-label">消费金金额：</div>
                <div class="order-value">{{ orderItem.fin_coup_amt }}元</div>
            </div>
            <div class="order-item" v-show="orderItem.full_act_amt > 0">
                <div class="order-label">满减金额：</div>
                <div class="order-value">{{ orderItem.full_act_amt }}元</div>
            </div>
            <div class="order-item" v-show="orderItem.coupon_amount > 0">
                <div class="order-label">优惠券抵扣金额：</div>
                <div class="order-value">{{ orderItem.coupon_amount }}元</div>
            </div>
        </div>

        <div class="status-operation">
            <router-link class="operation-link" to="/">返回主页</router-link>
            <div v-if="orderItem.order_status == '00'" class="operation-btn" @click="buyNow(orderItem.order_no)">立即付款</div>
        </div>

    </div>

</template>

<script setup>
import { ref } from 'vue'

import Navbar from '../../components/navbar.vue';
import { useRoute } from 'vue-router/composables';
import * as $function from '../../share/function.js';
import * as api from '../../http-service/api-client';

const route = useRoute();

const orderItem = ref({})

getOrdersDetail()
async function getOrdersDetail() {
    let id = route.query.id
    const result = (await api.ordersDetail(id)).data;
    if (result.code == 200) {
        orderItem.value = result.data
    } else {
        alert(result.msg)
    }
}

// 表单防重，是否可以提交表单
var isSubmit = true 

// 支付
async function buyNow(order_no) {
    let user_info = sessionStorage.getItem('userInfo')
    if (!user_info) {
        // 获取授权
        AlipayJSBridge.call('cgbGetAuthCode', {
            scope: 'auth_user',
            appId: '01202404190000020001',
            receiverAppId: '01202404190000020001',
            receiverMerId: '0120240419000002'
        },
        (res) => {
            // 授权失败，返回上一页
            if (res.respCode != '0000') {
                history.back();
            }
                
            api.getUserInfo({"authCode": res.authCode}).then(result => {
                if (result.data.msg == '') {
                    const userInfo = JSON.stringify(result.data.data)
                    sessionStorage.setItem('userInfo', userInfo)
                }
            })
        })
    }
    if (isSubmit === false) {
        console.log('不可重复提交')
        return false
    }
    isSubmit = false

    var args = {
        orderNo: order_no
    }
    var result = (await api.buyNow(args)).data
    console.log('buyNow()````````')
    console.log(result)
    if (result.code != 200) {
        alert(result.msg)
        return false
    }    

    var data = {
        keyCiphertext: result.data.keyCiphertext,
        textToDecrypt: result.data.textToDecrypt,
        signature: result.data.signature,
        appId: result.data.appId,
        businessType: "06",
        // isCallback: false
    }
 
    AlipayJSBridge.call('cgbPay', data,
    (res) => {
        isSubmit = true
        if (res.respCode == '0000') {
            alert(res.respMsg)
        
            orderItem.value.order_status = '01'
        } else {
            var msg = res.errorMsg ? res.errorMsg : res.respMsg
            alert(msg)
        }
    }
)
}

</script>

<style lang="stylus" scoped>

.page {
    padding 182px 35px 0
    text-align center
    color: #333333;
}

.status-icon {    
    img {
        height 100px
        margin auto
        width 100px
    }
}

.status-text {
    margin-top 28px
    font-weight: 500;
    font-size: 40px; 
    line-height: 56px;     
}

.status-countdown {
    height 33px
    margin 11px 0
    font-weight: 500;
    font-size: 24px; 
    line-height: 33px;
}

.status-order {
    
    font-weight: 500;
    font-size: 26px;    
    line-height: 37px;
    margin-bottom 42px
    div {
        margin-top 11px
    }
}

.order-info {
    border: 1px solid #D8D8D8;
    border-width: 1px 0;
    padding 28px 0

    .order-name {
        font-size: 26px; 
        line-height: 30px;
        text-align left
        margin-bottom 11px
    }

    .order-item {
        display: flex;
        justify-content: space-between;         
        font-weight: 500;
        font-size: 26px;
        color: #333333;
        line-height: 37px;
    }
}

.status-operation {
    margin 28px 0
}

.operation-btn {
    background-color: #FF7979;
    color: #fff;
    border-radius: 999px;
    text-align: center;
    height 98px
    font-size: 26px; 
    line-height: 98px;
    
}

.operation-link {
    color: #4A90E2;
    text-decoration: underline;
    padding 22px 0
    font-weight: 500;
    font-size: 26px;
    color: #4A90E2;
    line-height: 37px;     
    display block
}

</style>