import axios from 'axios';
// import store from '@/store/loginModule.js';
import { objectTranferArray } from '../share/methods.js';
var baseURLVal
if (process.env.NODE_ENV === 'production') {
    baseURLVal = ''
} else {
    baseURLVal = '/'
}
var httpRequest = axios.create({
    // baseURL: process.env.NODE_ENV === 'production' ? '' : '/api', //基本地址
    baseURL: baseURLVal, //基本地址
    timeout: 15000 //超时时间
});
const loginUrl = '#/account/login';
let returnUrl = [];
// function create(args) {
//     if (args.length % 2 != 0) {
//         throw Error(`[HttpRequestParameters] invalid args count: ${args.length}. Must be able to be divided by 2.`);
//     }
//     let self = {};
//     if (args.length > 0 && typeof args[1] === 'object') {
//         args = objectTranferArray(args[1]);
//     }
//     for (let i = 0; i < args.length; i += 2) {
//         self[args[i]] = args[i + 1] == undefined || JSON.stringify(args[i + 1]) == "{}" || JSON.stringify(args[i + 1]) == "[]" ? '' : args[i + 1];
//     }
//     return self;
// }

// function toUri(object) {
//     let list = [];
//     for (let key in object) {
//         object[key] = typeof object[key] === 'object' && object[key] != null ? JSON.stringify(object[key]) : object[key];
//         list.push(`${key}=${encodeURIComponent(object[key])}`);
//     }
//     return list.join('&');
// }

export function get(url, params) {
    var str = sessionStorage.getItem('userInfo')
    var userInfo = {}
    if (str) {
        userInfo = JSON.parse(str)
    }
    
    return new Promise((resolve, reject) =>
        httpRequest
            .get(url, {
                params,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'Authorization': "Bearer " + userInfo.accessToken
                }
            })
            .then(res => {
                if (res.data.type == 'login.timeout' || res.data.type == 'login.token' || res.data.type == 'login.ip') {
                    if (returnUrl.length === 0) {
                        returnUrl = window.location.href.split('#');
                        window.location.href = loginUrl + '?returnUrl=' + returnUrl[1];
                    } else {
                        window.location.href = loginUrl + '?returnUrl=' + returnUrl[1];
                    }
                }
                if (res.data.code === 0) {
                    reject(res);
                    return;
                }
                resolve(res);
            })
            .catch(error => {
                reject({ data: { msg: '网络开了小差,请稍后重试！' } });
            })
    );
}

export function post(url, args) {

    axios.interceptors.request.use(config => {
        // 如果存在Referer头部，则删除它
        if (config.headers.common['Referer']) {
            delete config.headers.common['Referer'];
        }
        return config;
    }
    )
    var str = sessionStorage.getItem('userInfo')
    var userInfo = {access_token: ""}
    if (str) {
        userInfo = JSON.parse(str)
    }
    // console.log(args)
   
    return new Promise((resolve, reject) =>
        httpRequest
            .post(url, args, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + userInfo.access_token
                }
            })
            .then(res => {
                // console.log('postRes````````````')
                // console.log(res)
                // if (res.data.code === 300) {
                //     reject({ data: { msg: res.data.msg } });
                //     return;
                // }
                resolve(res);
            })
            .catch(error => {
                console.log(error)
                // reject({ data: { msg: error.response.data.msg } });
                reject(error);
                resolve(error);
            })
    );
}
