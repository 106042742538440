import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=09a32c17&scoped=true"
import script from "./detail.vue?vue&type=script&lang=js"
export * from "./detail.vue?vue&type=script&lang=js"
import style0 from "./detail.vue?vue&type=style&index=0&id=09a32c17&prod&lang=stylus&scoped=true"
import style1 from "./style.styl?vue&type=style&index=1&id=09a32c17&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09a32c17",
  null
  
)

export default component.exports