export function orderStatus(status){
    switch(status){
        case '00':
            return '待支付';
            break;
        case '01':
            return '已支付';
            break;
        case '03':
            return '已取消';
            break;
    }
}

export function getMinutesDiff(order_lose_time) {
    if(!order_lose_time)
    return 0;

    const currentTime = Math.floor(Date.now() / 1000); // 当前时间戳（单位：秒）
    const diffSeconds = order_lose_time - currentTime;
    const diffMinutes = Math.floor(diffSeconds / 60);
    return diffMinutes;
}