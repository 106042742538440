import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import OrderView from '../views/order/index.vue'
import OrderDetailView from '../views/order/detail.vue'
import HomeView from '../views/home/index.vue'
import HomeDetailView from '../views/home/detail.vue'
import PackageView from '../views/package/index.vue'
import PackageDetailView from '../views/package/detail.vue'
import PackageInfoView from '../views/package/info.vue'
import InfoView from '../views/order/info.vue'

const routes = [
  { path: '/', component: HomeView },
  { path: '/order', component: OrderView },
  { path: '/home/detail', component: HomeDetailView },
  { path: '/package', component: PackageView },
  { path: '/package/detail', component: PackageDetailView },
  { path: '/package/info', component: PackageInfoView },
  { path: '/order/detail', component: OrderDetailView },
  { path: '/order/info', component: InfoView },
]

const router = new VueRouter({
  routes
})

export default router
