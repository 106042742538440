<template>

    <div class="page">

        <div class="status-icon">
            <img v-if="orderItem.order_status == '01'" src="@/assets/icon-success.png" alt="">
            <img v-if="orderItem.order_status == '00'" src="@/assets/icon-cancel.png" alt="">
        </div>
        <div class="status-text">
            {{ orderStatus(orderItem.order_status) }}
        </div>

        <div v-show="orderItem.order_status == '00'" class="status-countdown">
            <!-- 这个div不能删，对span做显示隐藏 -->
            <span>剩余：{{ getMinutesDiff(orderItem.lose_time) }}分钟</span>
        </div>

        <div class="status-order">
            <div>订单时间：{{ orderItem.created_at }}</div>
            <div>订单号：{{ orderItem.order_no }}</div>
        </div>

        <div class="order-info">

            <div class="order-name">{{ orderItem.product_name }}</div>

            <div class="order-item">
                <div class="order-label">订单金额：</div>
                <div class="order-value">{{ orderItem.cash_amount }}元</div>
            </div>
            <div class="order-item">
                <div class="order-label">实付金额：</div>
                <div class="order-value">{{ orderItem.total_amount }}元</div>
            </div>

        </div>

        <!-- 优惠金额 -->
        <div v-show="orderItem.total_amount != orderItem.cash_amount" class="order-info">
            <!-- <div class="order-name">优惠详情</div> -->
            <div class="order-item" v-show="orderItem.integral_amount > 0">
                <div class="order-label">积分抵扣金额：</div>
                <div class="order-value">{{ orderItem.integral_amount }}元</div>
            </div>
            <div class="order-item" v-show="orderItem.random_act_amt > 0">
                <div class="order-label">随机减：</div>
                <div class="order-value">{{ orderItem.random_act_amt }}元</div>
            </div>
            <div class="order-item" v-show="orderItem.fin_coup_amt > 0">
                <div class="order-label">消费金金额：</div>
                <div class="order-value">{{ orderItem.fin_coup_amt }}元</div>
            </div>
            <div class="order-item" v-show="orderItem.full_act_amt > 0">
                <div class="order-label">满减金额：</div>
                <div class="order-value">{{ orderItem.full_act_amt }}元</div>
            </div>
            <div class="order-item" v-show="orderItem.coupon_amount > 0">
                <div class="order-label">优惠券抵扣金额：</div>
                <div class="order-value">{{ orderItem.coupon_amount }}元</div>
            </div>
        </div>

        <div class="status-operation">
            <div v-if="orderItem.order_status == '00'" class="operation-btn" @click="buyNow(orderItem.order_no)">立即付款</div>
        </div>

    </div>

</template>

<script>
import Navbar from '../../components/navbar.vue';
import * as api from '../../http-service/api-client';

export default {
    name: 'Info',
    components: {
        Navbar
    },
    data(){
        return {
            isSubmit: true,
            orderItem: {},
        }
    },
    methods: {
        // 获取商品详情
        getOrdersDetail: function(){
            let id = this.$route.query.id
            api.ordersDetail(id).then(res => {
                let result = res.data
                if (result.code == 200) {
                    this.orderItem = result.data
                } else {
                    alert(result.msg)
                }
            })
        },
        // 订单状态文字输出
        orderStatus: function(status){
            switch(status){
                case '00':
                    return '待支付';
                    break;
                case '01':
                    return '已支付';
                    break;
                case '03':
                    return '已取消';
                    break;
            }
        },
        // 倒计时
        getMinutesDiff: function(order_lose_time) {
            if(!order_lose_time)
            return 0;

            const currentTime = Math.floor(Date.now() / 1000); // 当前时间戳（单位：秒）
            const diffSeconds = order_lose_time - currentTime;
            const diffMinutes = Math.floor(diffSeconds / 60);
            
            return diffMinutes;
        },
        // 获取支付参数
        buyNow: function(order_no){
            if (this.isSubmit === false) {
                console.log('不可重复提交')
                return false
            }
            this.isSubmit = false

            let args = {
                orderNo: order_no
            }
            api.buyNow(args).then(res => {
                let result = res.data
                if (result.code == 200) {
                    // this.orderItem = result.data
                    this.cgbPay(result)
                } else {
                    alert(result.msg)
                }
            })

            
        },
        // 调起支付
        cgbPay: function(result){
            let data = {
                keyCiphertext: result.data.keyCiphertext,
                textToDecrypt: result.data.textToDecrypt,
                signature: result.data.signature,
                appId: result.data.appId,
                businessType: "06",
                // isCallback: false
            }

            AlipayJSBridge.call('cgbPay', data,
            (res) => {
                this.isSubmit = true
                if (res.respCode == '0000') {
                    alert(res.respMsg)
                
                    orderItem.value.order_status = '01'
                } else {
                    var msg = res.errorMsg ? res.errorMsg : res.respMsg
                    alert(msg)
                }
            })
        }
    },
    created(){
        this.getOrdersDetail()
    }
}

</script>

<style lang="stylus" scoped>

.page {
    padding 182px 35px 0
    text-align center
    color: #333333;
}

.status-icon {    
    img {
        height 100px
        margin auto
        width 100px
    }
}

.status-text {
    margin-top 28px
    font-weight: 500;
    font-size: 40px; 
    line-height: 56px;     
}

.status-countdown {
    height 33px
    margin 11px 0
    font-weight: 500;
    font-size: 24px; 
    line-height: 33px;
}

.status-order {
    
    font-weight: 500;
    font-size: 26px;    
    line-height: 37px;
    margin-bottom 42px
    div {
        margin-top 11px
    }
}

.order-info {
    border: 1px solid #D8D8D8;
    border-width: 1px 0;
    padding 28px 0

    .order-name {
        font-size: 26px; 
        line-height: 30px;
        text-align left
        margin-bottom 11px
    }

    .order-item {
        display: flex;
        justify-content: space-between;         
        font-weight: 500;
        font-size: 26px;
        color: #333333;
        line-height: 37px;
    }
}

.status-operation {
    margin 28px 0
}

.operation-btn {
    background-color: #FF7979;
    color: #fff;
    border-radius: 999px;
    text-align: center;
    height 98px
    font-size: 26px; 
    line-height: 98px;
    
}

.operation-link {
    color: #4A90E2;
    text-decoration: underline;
    padding 22px 0
    font-weight: 500;
    font-size: 26px;
    color: #4A90E2;
    line-height: 37px;     
    display block
}

</style>